<script>

import GerconCardMetricList from "../gerkon/GerconCardMetricList";

export default {
    name: "LeakCardMetricList",
    extends: GerconCardMetricList,
    data() {
        return {
            textStatus1: this.$t('Обнаружено'),
            textStatus0: this.$t('Не обнаружено'),
            classStatus1: null,
            classStatus0: null,
        }
    }
}
</script>
