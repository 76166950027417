<script>

import DefaultCard from "../default/DefaultCard";
import GerkonCardHeader from "../gerkon/GerkonCardHeader";
import LeakCardMetricList from "./LeakCardMetricList";

export default {
    name: "LeakCard",
    extends: DefaultCard,
    components: {
        HeaderComponent: GerkonCardHeader,
        MetricList: LeakCardMetricList,
    }
}
</script>
